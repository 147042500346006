export function formatAdmissionDeadline(dateString: string) {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  };

  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString('en-GB', options)
    .replace(/ /g, ' ');
  const hasPassed = date < new Date();

  return {
    formattedDate,
    hasPassed
  };
}

export function getYearFromTimestamp(timestamp: string) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  return year;
}

export function formattedDate(inputDate: string): string {
  if (!inputDate) return '';
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getNextTenYears(): { label: string; value: string }[] {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let i = 0; i < 2; i++) {
    const year = currentYear + i;
    yearsArray.push({
      label: year.toString(),
      value: year.toString()
    });
  }

  return yearsArray;
}

export const toKebabCase = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '_')
    .toLowerCase();
};

export const toTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (c) => c.toUpperCase());
};
